<template>
  <div id="simple-filter" class="card">
    <div class="row items-center">
      <div class="col">
        <div class="row">
          <template>
            <div
              v-if="selectedfid === '-1'"
              class="backGround row q-ml-sm q-mr-sm q-mt-sm q-mb-sm"
              style="font-size: larger; font-weight: 700; color: #643094"
            >
              All Save Search
            </div>
            <div v-if="selectedfid != '-1'" class="backGround row">
              <SelectField
                v-if="workspace"
                v-model="workSpaceId"
                is-mandatory
                placeholder="Select Workspace"
                is-clearable
                :options="workspaceList"
                class="fieldList q-ml-sm q-mr-sm q-mt-sm q-mb-sm"
                @input="$emit('update:workspaceId', workSpaceId)"
              />

              <SelectField
                v-if="!workspace || workSpaceId"
                v-model="selectedId"
                is-mandatory
                :placeholder="name"
                is-clearable
                :options="list"
                class="fieldList q-ml-sm q-mr-sm q-mt-sm q-mb-sm"
                @input="getData"
              />
            </div>
          </template>

          <template v-for="(group, groupIdx) in filterGroups">
            <div :key="group.id" class="backGround">
              <ValidationObserver
                v-for="(filter, filterIdx) in group.filters"
                ref="form"
                :key="filter.id"
                class="row"
              >
                <!-- criteria -->

                <ValidationProvider
                  :key="filter.id"
                  v-slot="{ errors }"
                  name="criteria"
                  :rules="{ required: true }"
                  class="col q-mr-xs"
                >
                  <SelectField
                    v-model="filter.criteria"
                    is-mandatory
                    is-clearable
                    :options="_columns"
                    :error="errors[0]"
                    class="fieldList q-ml-sm q-mr-xs q-mt-sm q-mb-sm"
                    @input="applyDataType(groupIdx, filterIdx, filter.criteria)"
                  />
                </ValidationProvider>

                <!-- ... -->

                <!-- value -->

                <!-- text -->

                <ValidationProvider
                  v-if="
                    filter.dataType === 'SHORT_TEXT' ||
                    filter.dataType === 'BARCODE'
                  "
                  v-slot="{ errors }"
                  name="value"
                  :rules="{
                    required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                      filter.condition
                    ),
                  }"
                  class="col q-mr-xs"
                >
                  <TextField
                    v-model="filter.value"
                    is-mandatory
                    :is-disabled="
                      ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                    "
                    :error="errors[0]"
                    class="textField q-mt-sm"
                  />
                </ValidationProvider>

                <!-- ... -->

                <!-- date -->

                <ValidationProvider
                  v-else-if="filter.dataType === 'DATE'"
                  v-slot="{ errors }"
                  name="value"
                  :rules="{
                    required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                      filter.condition
                    ),
                  }"
                  class="row q-mr-xs"
                >
                  <DateField
                    v-model="filter.value"
                    is-mandatory
                    :is-disabled="
                      ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                    "
                    :error="errors[0]"
                    class="dateField q-mr-xs q-mt-sm"
                    placeholder="From Date"
                  />

                  <DateField
                    v-model="filter.valueTo"
                    is-mandatory
                    :is-disabled="
                      ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                    "
                    :error="errors[0]"
                    class="dateField q-mt-sm"
                    placeholder="To Date"
                  />
                </ValidationProvider>

                <!-- ... -->

                <!-- select -->

                <ValidationProvider
                  v-else-if="
                    filter.dataType === 'SINGLE_SELECT' ||
                    filter.dataType === 'SINGLE_CHOICE'
                  "
                  v-slot="{ errors }"
                  name="value"
                  :rules="{
                    required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                      filter.condition
                    ),
                  }"
                  class="col q-mr-xs"
                >
                  <template v-if="apiPath && !getOptions(filter.criteria)">
                    <AsyncSelectField
                      v-model="filter.arrayValue"
                      is-mandatory
                      :is-disabled="
                        ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                      "
                      :error="errors[0]"
                      :api-path="apiPath"
                      :column-name="filter.criteria"
                      class="fieldList q-mt-sm q-mb-sm"
                      @input="
                        {
                          $emit('update');
                          applyFilterType(
                            groupIdx,
                            filterIdx,
                            filter.criteria,
                            filter.arrayValue
                          );
                        }
                      "
                    />
                  </template>
                  <template v-else>
                    <SelectField
                      v-model="filter.arrayValue"
                      is-mandatory
                      :is-disabled="
                        ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                      "
                      :is-searchable="true"
                      :options="getOptions(filter.criteria)"
                      :error="errors[0]"
                      class="fieldList q-mt-sm q-mb-sm"
                      @input="
                        {
                          $emit('update');
                          applyFilterType(
                            groupIdx,
                            filterIdx,
                            filter.criteria,
                            filter.arrayValue
                          );
                        }
                      "
                    />
                  </template>
                </ValidationProvider>

                <!-- ... -->

                <!-- text -->

                <ValidationProvider
                  v-else
                  v-slot="{ errors }"
                  name="value"
                  :rules="{
                    required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                      filter.condition
                    ),
                  }"
                  class="col q-mr-xs"
                >
                  <TextField
                    v-model="filter.value"
                    is-mandatory
                    :is-disabled="
                      ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                    "
                    :error="errors[0]"
                    class="textField q-mt-sm"
                  />
                </ValidationProvider>

                <!-- ... -->

                <!-- ... -->

                <!-- search -->

                <div class="col-auto q-mt-md">
                  <BaseActionButton
                    v-tooltip.top="'search filter'"
                    icon="eva-search"
                    class="plus q-mr-xs"
                    size="20px"
                    color="primary"
                    is-flat
                    @click="
                      {
                        $emit('update');
                        applyFilterType(groupIdx, filterIdx, filter.arrayValue);
                      }
                    "
                  />
                </div>

                <!-- ... -->

                <!-- remove -->

                <div class="col-auto q-mt-md">
                  <BaseActionButton
                    v-tooltip.top="'remove filter'"
                    icon="mdi-close"
                    class="plus q-mr-xs"
                    size="14px"
                    is-flat
                    color="red"
                    @click="removeFilterGroup(groupIdx)"
                  />
                </div>

                <!-- ... -->
              </ValidationObserver>
            </div>
          </template>

          <BaseActionButton
            v-if="selectedId && activeView === 'TABLE'"
            v-tooltip.top="'add filter'"
            icon="mdi-plus"
            color="secondary"
            class="q-ml-xs q-mt-sm q-mb-sm"
            @click="addFilterGroup"
          />
        </div>
      </div>
      <p v-if="dateValue" class="report-header text-uppercase text-primary">
        report generated for : {{ weekValue }} {{ monthValue }} {{ yearValue }}
      </p>

      <div v-if="!noFilter">
        <BaseActionButton
          v-if="selectedId"
          v-tooltip.top="'Save Filter'"
          icon="mdi-content-save-cog"
          color="primary"
          class="q-ml-xs q-mt-sm q-mb-sm q-mr-sm"
          @click="$emit('saveData')"
        />

        <SelectField
          v-if="!selectedId"
          v-model="selectedfid"
          is-mandatory
          :placeholder="'Saved Reports' + '   ' + '(' + filterList.length + ')'"
          is-clearable
          :options="filterList"
          class="fieldList q-ml-sm q-mr-sm q-mt-sm q-mb-sm"
          @click="$emit('viewFilter')"
          @input="filterData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import AsyncSelectField from "@/components/common/form/async-select-field/AsyncSelectField.vue";

export default {
  name: "SimpleFilter",

  components: {
    ValidationObserver,
    ValidationProvider,
    TextField,
    SelectField,
    DateField,
    AsyncSelectField,
  },

  props: {
    id: {
      type: Number,
      default: 0,
    },

    columns: {
      type: Array,
      default: () => [],
    },

    filterBy: {
      type: Array,
      default: () => [],
    },

    workspace: {
      type: Boolean,
      default: false,
    },

    name: {
      type: String,
      required: true,
    },

    list: {
      type: Array,
      default: () => [],
    },

    wId: {
      type: Number,
      default: 0,
    },

    workspaceList: {
      type: Array,
      default: () => [],
    },

    module: {
      type: String,
      default: "",
    },

    moduleId: {
      type: [String, Number],
      default: 0,
    },

    activeView: {
      type: String,
      default: "",
    },

    dateValue: {
      type: Boolean,
      default: false,
    },

    weekValue: {
      type: String,
      default: "",
    },

    monthValue: {
      type: String,
      default: "",
    },

    yearValue: {
      type: String,
      default: "",
    },

    filterList: {
      type: Array,
      default: () => [],
    },

    fid: {
      type: String,
      default: "",
    },

    noFilter: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      workSpaceId: 0,
      selectedId: 0,
      filterGroups: [],
      WfilterId: 0,
      selectedfid: "",
      filters: false,
    };
  },

  computed: {
    _columns() {
      var data = this.columns.reduce((ids, column) => {
        if ("isFilter" in column) {
          if (column.isFilter) {
            ids.push({
              id: column.id,
              label: column.label,
              value: column.name,
              dataType:
                column.dataType !== "TITLE" ? column.dataType : "SHORT_TEXT",
            });
          }
        } else {
          ids.push({
            id: column.id,
            label: column.label,
            value: column.name,
            dataType:
              column.dataType !== "TITLE" ? column.dataType : "SHORT_TEXT",
          });
        }
        return ids;
      }, []);

      return data;
    },

    apiPath() {
      if (this.module === "repository") {
        return `/repository/${this.moduleId}/uniqueColumnValues`;
      } else if (this.module === "form") {
        return `/form/${this.moduleId}/uniqueColumnValues`;
      }
      return "";
    },
  },

  watch: {
    id: {
      deep: true,
      immediate: true,
      handler() {
        if (this.id) {
          this.selectedId = this.id;
        }
      },
    },

    workSpaceId: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.workSpaceId) {
          this.filterGroups = [];
        }
      },
    },

    filterGroups: {
      deep: true,
      immediate: true,
      handler() {
        if (this.filterGroups.length) {
          this.filterGroups[0].groupCondition = "";
          this.$emit("update:filterBy", this.filterGroups);
        }
      },
    },

    activeView: {
      immediate: true,
      handler() {
        if (this.activeView === "CHART") {
          this.filterGroups = [];
        }
      },
    },

    fid: {
      deep: true,
      immediate: true,
      handler() {
        if (this.fid) {
          this.selectedfid = this.fid;
          this.filterData();
        }
      },
    },

    wId: {
      deep: true,
      immediate: true,
      handler() {
        if (this.wId) {
          this.workSpaceId = Number(this.wId);
        }
      },
    },

    filterBy: {
      deep: true,
      immediate: true,
      handler() {
        if (this.filters) {
          this.filterGroups = this.filterBy;
          console.log(this.filterGroups, "groups");
        }
        this.filters = false;
      },
    },
  },

  methods: {
    addFilterGroup() {
      this.filterGroups.push({
        id: this.$nano.id(),
        filters: [
          {
            id: this.$nano.id(),
            criteria: "",
            condition: "",
            value: "",
            valueTo: "",
            arrayValue: "",
          },
        ],
        groupCondition: this.filterGroups.length ? "" : "",
      });
    },

    removeFilterGroup(groupIdx) {
      this.filterGroups.splice(groupIdx, 1);
      if (this.selectedId) {
        this.$emit("update");
      }
    },

    addFilter(groupIdx) {
      this.filterGroups[groupIdx].filters.push({
        id: this.$nano.id(),
        criteria: "",
        condition: "",
        value: "",
      });
    },

    removeFilter(groupIdx, filterIdx) {
      this.filterGroups[groupIdx].filters.splice(filterIdx, 1);
    },

    applyDataType(groupIdx, filterIdx, name) {
      this.filterGroups[groupIdx].filters[filterIdx].value = "";
      this.filterGroups[groupIdx].filters[filterIdx].condition = "";
      this.filterGroups[groupIdx].filters[filterIdx].condition = "CONTAINS";

      this.filterGroups[groupIdx].filters[filterIdx].dataType = "";
      this.filterGroups[groupIdx].groupCondition = "and";
      let type = this._columns.find((item) => item.value === name);
      this.filterGroups[groupIdx].filters[filterIdx].selectedCriteria =
        type.label;
      if (type) {
        this.filterGroups[groupIdx].filters[filterIdx].dataType = type.dataType;
        if (type.dataType === "SINGLE_SELECT") {
          this.filterGroups[groupIdx].filters[filterIdx].condition =
            "IS_EQUALS_TO";
        }
      }
    },

    getData() {
      if (this.selectedId) {
        this.$emit("update:id", this.selectedId);
      } else {
        this.$emit("update:id", 0);
      }
      this.filterGroups = [];
      this.$emit("update:filterBy", this.filterGroups);
    },

    getOptions(name) {
      return this.columns.find((column) => column.name === name)?.options;
    },

    applyFilterType(groupIdx, filterIdx, name, value) {
      let options = this.columns.find(
        (column) => column.name === name
      )?.options;
      let selectedOption = options.find((item) => item.value === value);
      this.filterGroups[groupIdx].filters[filterIdx].selectedValue =
        selectedOption.label;
    },

    filterData() {
      if (this.selectedfid) {
        if (this.selectedfid === "-1") {
          this.$emit("update:fid", this.selectedfid);
          this.workspace = false;
          this.selectedId = 0;
          this.workSpaceId = 0;
        } else {
          this.filters = true;
          this.id = this.selectedId;
          this.wId = this.workSpaceId;
        }
        this.$emit("update:fid", this.selectedfid);
      } else if (!this.selectedfid) {
        this.selectedId = 0;
        this.workSpaceId = 0;
        this.$emit("update:fid", "");
      } else {
        this.$emit("update:fid", "");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 4px;
  border-style: none solid none none;
  border: 1px solid var(--divider-color);

  .description {
    color: var(--icon-color-inverted);
    margin-bottom: 4px;
  }
}

.plus {
  height: 20px !important;
  width: 20px !important;
}

.backGround {
  border: 2px;
  border-radius: 10px;
}
</style>
<style lang="scss">
#simple-filter {
  #select-field.fieldList {
    .select {
      min-height: 0px !important;
      color: $secondary !important;
      overflow: hidden !important;
      max-width: 140px;
      min-width: 140px;
    }
  }

  #text-field.textField {
    input {
      min-height: 35px;
      width: 140x;
    }
  }

  #date-field.dateField {
    input {
      min-height: 35px;
      width: 140px;
    }
  }

  #async-select-field.fieldList {
    .async-select {
      min-height: 0px !important;
      color: $secondary !important;
      overflow: hidden !important;
      width: 140px;
    }
  }

  .report-header {
    text-align-last: center;
    width: max-content;
    padding-right: 12px;
    padding-top: 12px;
    font-weight: 600;
  }
}
</style>
